import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo from '../../img/logo_aurora.png'

export default function Head({school}) {
  console.log(school.truong)
  return (
    <HelmetProvider>
        <div>
            <Helmet>
                <link rel="icon" href={school.logo} />
                <title>{school.title}</title>
            </Helmet>
        </div>
    </HelmetProvider>
  )
}
