import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './MainPage';
import Search from './Search';

export default function Router({isMobile, school}) {
  return (
    <Routes>
        <Route path="/" element={<Main isMobile={isMobile} school={school} />} />
        <Route path="/tra-cuu" element={<Search isMobile={isMobile} school={school}/>} />

        <Route path="*" element={<></>} />
    </Routes>
  )
}
