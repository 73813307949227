import React from 'react'
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { IoMdNotifications } from "react-icons/io";
import { FaUserCircle, FaChevronDown, FaFileAlt } from "react-icons/fa";

export default function Header({isMobile, school}) {
  return (
    <>
        <div style={{display:'flex', justifyContent:'space-between', padding:'20px 40px', backgroundColor:'#fff'}}>
            <img src={school.logo} alt='logo' style={{width:'100px', margin:`${isMobile?'0 auto':''}`}} />
            <div style={{display:'flex', justifyItems:'center', paddingRight:'40px', paddingTop:'20px'}}>
                <IoMdNotifications size={'25px'} color='#57585b'/>
                <a href='https://id.myu.vn/account/login?response_type=code&client_id=myu_web&redirect_uri=https%3A%2F%2Fmyu.vn%2Fauth%2Foauth'>
                    <FaUserCircle size={'23px'} style={{marginLeft:'20px'}} color='#ccc' />
                </a>
            </div>
        </div>

        <Grid container spacing={2} sx={{display:`${isMobile?'none':''}`}}>
            <Grid item xs={12} md={5}></Grid>
            <Grid item xs={12} md={7}>
                <ul style={{display:'flex', justifyItems:'center', listStyle:'none', fontSize:`${isMobile?'13px':'15px'}`}}>
                    <a href={school.link} style={{textDecoration:'none', color:school.color, padding:'5px 20px 0 20px'}}><li style={{fontWeight:'bold'}}>Trang chủ</li></a>
                    <a href={school.link_gioithieu} style={{textDecoration:'none', color:school.color, padding:'5px 20px 0 20px'}}><li style={{fontWeight:'bold'}}>Thông tin về Trường</li></a>
                    <Link to='/tra-cuu' style={{textDecoration:'none', color:school.color, padding:'5px 60px 0 20px'}}><li style={{fontWeight:'bold'}}>Tra cứu</li></Link>
                    {
                        isMobile ? <></> :
                        <li style={{backgroundColor:school.color, color:'#fff', fontWeight:'bold', padding:'5px 10px', borderRadius:'5px'}}>Mã trường: {school.maTruong}</li>
                    }
                </ul>
            </Grid>
        </Grid>

        <ul style={{display:`${isMobile?'flex':'none'}`, justifyItems:'center', listStyle:'none', padding:'0 20px'}}>
            <a href={school.link} style={{textDecoration:'none', color:school.color, padding:'5px 10px'}}><li style={{fontWeight:'bold'}}>Trang chủ</li></a>
            <a href={school.link_gioithieu} style={{textDecoration:'none', color:school.color, padding:'5px 10px'}}><li style={{fontWeight:'bold'}}>Thông tin về Trường</li></a>
            <Link to='/tra-cuu' style={{textDecoration:'none', color:school.color, padding:'5px 10px'}}><li style={{fontWeight:'bold'}}>Tra cứu</li></Link>
        </ul>

        <p style={{display:`${isMobile?'':'none'}`, backgroundColor:school.color, color:'#fff', fontWeight:'bold', padding:'5px 10px', borderRadius:'5px', margin:'0 30px', textAlign:'center'}}>Mã trường: {school.maTruong}</p>
    </>
  )
}
