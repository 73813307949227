import React, {useState} from 'react'
import Header from './Header'
import TextField from '@mui/material/TextField';
import { IoSearchOutline } from "react-icons/io5";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Search({isMobile, school}) {
    const [data, setData] = useState([
        {
            ten: '--',
            phuongthuc: '--',
            diem: '--',
            tinhtrang: '--'
        }
    ])

    const [dataSearch, setDataSearch] = useState('')

    const handleSubmit = ()=>{
        // code submit
        console.log('Dữ liệu đã nhập:', dataSearch)
    }

  return (
    <div style={{width:'100%', backgroundColor:'#f0f5fd', paddingBottom:'20px'}}>
        <Header isMobile={isMobile} school={school}/>

        <div style={{width:`${isMobile?'90%':'50%'}`, position:'relative', margin:'auto', marginTop:'20px', boxShadow:'5px 5px 5px #ccc'}}>
            <TextField id="outlined-basic" label="Nhập CCCD để tra cứu..." size='medium' variant="outlined" 
                onChange={(e)=>setDataSearch(e.target.value)} value={dataSearch}   sx={{width:`100%`, backgroundColor:'#fff'}} />
            <IoSearchOutline size={'22px'} style={{position:'absolute', top:'16px', right:'15px', cursor:'pointer'}}
               onClick={handleSubmit} />
        </div>

        <div style={{width:`${isMobile?'100%':'90%'}`, margin:'auto', padding:'30px 0'}}>
            <TableContainer component={Paper}>
                <Table sx={{}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{fontWeight:'bold'}}>Thí sinh</TableCell>
                            <TableCell align="center" sx={{fontWeight:'bold'}}>Phương thức</TableCell>
                            <TableCell align="center" sx={{fontWeight:'bold'}}>Điểm</TableCell>
                            <TableCell align="center" sx={{fontWeight:'bold'}}>Tình trạng</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell align="center">{row.ten}</TableCell>
                            <TableCell align="center">{row.phuongthuc}</TableCell>
                            <TableCell align="center">{row.diem}</TableCell>
                            <TableCell align="center">{row.tinhtrang}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
  )
}
